import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import themeReducer, { IColorThemeState } from '@store/colorThemeStore'
import productsReducer, { IProductsState } from '@store/products'
import shoppingCartReducer, { IShoppingCartState } from '@store/shoppingСart'
import authReducer, { IAuthState } from '@store/auth'
import checkoutReducer, { ICheckoutState } from '@store/checkout'

export interface IStore {
  theme: IColorThemeState
  products: IProductsState
  cart: IShoppingCartState
  auth: IAuthState
  checkout: ICheckoutState
}

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    products: productsReducer,
    cart: shoppingCartReducer,
    auth: authReducer,
    checkout: checkoutReducer
  }
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
