import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../../../../styles'

export const StyledItemButtons = styled.div.attrs(() => ({
  className: 'item-buttons',
}))`
  display: flex;
  justify-content: end;
  gap: 0.5rem;
  height: 1.25rem;

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.375rem;
    height: 1.375rem;
    padding: 0;
    background-color: ${() => commonStyle.colors.grey100};
    border-radius: ${() => commonStyle.radii.radius4};
    cursor: pointer;
    user-select: none;
    
    .MuiButtonBase-root {
      width: 1.375rem;
      min-width: 1.375rem;
      height: 1.375rem;
      padding: 0;

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
`
