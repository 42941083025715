import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import { StyledPaymentPage } from '@components/views/paymentPage/styles/paymentPage.styled'
import { BackToCatalog } from '@components/views/components/backToCatalog/backToCatalog'

import { commonStyle } from '../../../styles'

export function PaymentPage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledPaymentPage>
        <BackToCatalog />

        <div className="payment-container">
          <h1>ОПЛАТА</h1>
          <br />
          <br />
          <h2>Для заказов из СПб:</h2>
          <br />
          <ul>
            <li>по счёту (для ИП и юр. лиц)</li>
            <li>по QR (для физ. лиц)</li>
            <li>наличными (при самовывозе)</li>
          </ul>
          <br className='br'/>
          <br />
          <br />
          <h2>Для заказов из Китая:</h2>
          <br />
          <br />
          <p>
            Осуществляется <b>100% предоплата товара</b> после согласования заказа, мы сами рассчитаем сумму в нужной
            вам
            валюте и вышлем соответствующие реквизиты.
          </p>
          <br />
          <p>
            <b>Стоимость доставки</b> будет известна после закупки, упаковки и доставки груза в транспортную, оплатить
            доставку
            можно по прибытии груза в Москву, сумму и реквизиты для оплаты вам сообщит менеджер транспортной компании.
          </p>
          <br />
          <ul>
            <li>
              <b>в рублях</b> перевод осуществляется на счета наших контрагентов, курс юаня актуален в течение суток и
              примерно равен рыночному
            </li>
            <li>
              <b>в юанях</b> перевод на карту физ.лица в Китае, реквизиты предоставляем по запросу
            </li>
          </ul>
        </div>
      </StyledPaymentPage>
    </ThemeProvider>
  )
}
