import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { StyledContactsPage } from '@components/views/contactsPage/styles/contactsPage.styled'
import { IStore } from '@store/store'
import { BackToCatalog } from '@components/views/components/backToCatalog/backToCatalog'

import vk from '../../../assets/img/social/vk.png'
import telegram from '../../../assets/img/social/telegram.png'
import chat from '../../../assets/img/social/chat.png'
import wechat from '../../../assets/img/social/wechat.png'
import email from '../../../assets/img/social/email.png'
import { commonStyle } from '../../../styles'

export function ContactsPage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledContactsPage>
        <BackToCatalog />

        <div className="contacts-container">
          <h1>Контакты</h1>
          <br />
          <br />
          <div className="card-container">
            <div className='social card'>
              <h2>Связь</h2>
              <div className="contacts">
                <div className="contact">
                  <img src={telegram} alt="telegram" />
                  <img src={chat} alt="chat" />
                  <p>+7 (953) 140-80-38 (чат)</p>
                </div>

                <div className="contact">
                  <img src={vk} alt="vk" />
                  <a href="https://vk.com/chaibezopt" target="_blank" rel="noreferrer">vk.com/chaibezopt</a>
                </div>

                <div className="contact">
                  <img src={wechat} alt="wechat" />
                  <p>wechat: <a>chaibezopt</a></p>
                </div>

                <div className="contact">
                  <img src={email} alt="email" />
                  <p>info@optchaibez.ru</p>
                </div>
              </div>
            </div>

            <div className="order card">
              <h2>ЗАКАЗ</h2>
              <br />
              <p className="small">
              Размещение оптовых заказов на сайте доступно круглосуточно, их обработка осуществляется менеджером в
                рабочее время:
              </p>
              <br />
              <div className='info'>
                <p><b>пн-сб</b> 11:00-20:00 по мск</p>
              </div>
            </div>

            <div className="pickup card">
              <h2>САМОВЫВОЗ</h2>
              <br />
              <p className="small">Наш склад расположен по адресу:</p>
              <br />
              <p>Санкт-Петербург,</p>
              <p>Захарьевская 29, офис 5</p>
              <br />
              <br />
              <div className="info">
                <p><b>пн-сб</b> 11:00-19:30 по мск</p>
              </div>
            </div>
          </div>
        </div>
      </StyledContactsPage>
    </ThemeProvider>
  )
}
