import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { StyledPricePage } from '@components/views/pricePage/styles/pricePage.styled'
import { IStore } from '@store/store'
import { BackToCatalog } from '@components/views/components/backToCatalog/backToCatalog'

import { commonStyle } from '../../../styles'
import rub from '../../../assets/img/rub.png'
import yuan from '../../../assets/img/yuan.png'

export function PricePage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const spbPriceFile = useSelector((state: IStore) => state.products.spbPriceFile)
  const chinaPriceFile = useSelector((state: IStore) => state.products.chinaPriceFile)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledPricePage>
        <BackToCatalog />

        <div className="price-container">
          <h1>Скачать прайс</h1>
          <br />
          <br />
          <p>Выберите прайс лист оптовых цен на китайский чай:</p>
          <br />
          <br />
          <div className="prices">
            <div className="price">
              <a className="card" href={spbPriceFile ?? ''}>
                <img src={rub} alt="rub" />
                <p className="text">со склада в СПб</p>
              </a>
            </div>

            <div className="price">
              <a className="card" href={chinaPriceFile ?? ''}>
                <img src={yuan} alt="yuan" />
                <p className="text">Из Китая</p>
              </a>
            </div>
          </div>
        </div>
      </StyledPricePage>
    </ThemeProvider>
  )
}
