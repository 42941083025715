import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from '@store/store'

import reportWebVitals from './reportWebVitals'
import App from './App'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

if (process.env.NODE_ENV === 'production') {
  window.onerror = (message, source, lineno, colno, error) => {
    // Логируем ошибку для анализа (опционально)
    console.error('Global error caught:', { message, source, lineno, colno, error })
    // Подавляем отображение ошибки
    return true
  }

  window.addEventListener('unhandledrejection', (event) => {
    console.error('Unhandled Promise Rejection:', event.reason)
    event.preventDefault()
  })
}

reportWebVitals()
