import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledPricePage = styled.div.attrs(() => ({
  className: 'price-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    "back"
    "price";

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;

  .price-container {
    grid-area: price;

    margin: 0 auto;

    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }

    p {
      font-size: ${() => commonStyle.fonts.fs14};
      line-height: 160%;
    }
    
    .prices {
      display: flex;
      gap: 2rem;
      
      .price {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 138px;
        
        .card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 138px;
          height: 150px;
          border-radius: ${() => commonStyle.radii.radius8};
          box-shadow: ${() => commonStyle.shadows.primaryShadow};
          cursor: pointer;
          user-select: none;
          transition: box-shadow ${() => commonStyle.times.time3};
          
          img {
            width: 37px;
            height: 46px;
          }
          
          p {
            font-size: ${() => commonStyle.fonts.fs12};
            font-weight: ${() => commonStyle.fontStyles.fw600};
          }
          
          &:hover {
            box-shadow: ${() => commonStyle.shadows.secondaryShadow};
            transition: box-shadow ${() => commonStyle.times.time3};
          }
        }
        
        .text {
          font-size: ${() => commonStyle.fonts.fs12};
          color: ${props => props.theme.secondColor};
        }
      }
    }
  }

  @media screen and (max-width: 761px) {
    .back-to-catalog {
      display: none;
    }
    
    .price-container {
      h1 {
        font-size: ${() => commonStyle.fonts.fs20};
        font-weight: ${() => commonStyle.fontStyles.fw600};
        text-align: start;
      }
      
      p {
        text-align: start;
      }
    }
  }
`
