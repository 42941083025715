import styled from 'styled-components'
import { commonStyle } from '../../../../../../styles'

export const StyledFilters = styled.div.attrs(() => ({
  className: 'filters',
}))`
  grid-area: filters;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  
  .filter-group-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .selectors-group {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    
    .categories-button {
      display: none;
    }
    
    .warning-text {
      margin-left: 5rem;
      color: ${() => commonStyle.colors.red};
    }
  }

  .filter-group-2 {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    width: 100%;

    .checkbox-container {
      display: flex;
      gap: 1rem;
    }
  }

  @media screen and (max-width: 961px) {
    .filter-group-1 {
      .categories-button {
        display: block;
      }

      .warning-text {
        margin-left: 2rem;
        font-size: ${() => commonStyle.fonts.fs11};
      }
    }
  }
`
