import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledPlugPage = styled.div.attrs(() => ({
  className: 'plug-page page',
}))`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: calc(100dvh - var(--header-height)) !important;
  width: 100%;
  text-align: center;
  
  header {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -1rem;
    width: 100%;
    height: var(--mobile-header-height);
    
    button {
      width: 24px !important;
      max-width: 24px !important;
      min-width: 24px !important;
      padding: 0;
    }
    
    .menu-logo {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      
      .logo {
        
      }
      
      .text {
        display: flex;
        flex-direction: column;
        align-items: start;
        text-transform: uppercase;
        
        .first {
          font-size: ${() => commonStyle.fonts.fs12};
          font-weight: ${() => commonStyle.fontStyles.fw600};
          color: ${() => commonStyle.colors.red};
        }
        
        .second {
          font-size: ${() => commonStyle.fonts.fs10};
          font-weight: ${() => commonStyle.fontStyles.fw600};
          color: ${props => props.theme.secondColor};
        }
      }
    }
  }
  
  .info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    
    h2 {
      font-size: ${() => commonStyle.fonts.fs14};
      font-weight: ${() => commonStyle.fontStyles.fw600};
    }
    
    p {
      font-size: ${() => commonStyle.fonts.fs14};
    }
    
    nav {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      
      div {
        display: flex;
        justify-content: center;
        gap: 1rem;
        
        p {
          font-size: ${() => commonStyle.fonts.fs20};
          font-weight: ${() => commonStyle.fontStyles.fw600};
          color: ${() => commonStyle.colors.red};
          text-transform: lowercase;
        }
      }
    }
    
    .logo-container {
      display: flex;
      flex-direction: column;
      gap: 3.125rem;
      
      .logo-group {
        display: flex;
        justify-content: center;
        gap: 2rem;
        
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
`
