import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore } from '@store/store'
import { StyledPurchasePage } from '@components/views/purchasePage/styles/purchasePage.styled'
import { BackToCatalog } from '@components/views/components/backToCatalog/backToCatalog'
import { ProductType } from '@endpoints/endpoints/products/types'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../styles'
import { useNavigate } from 'react-router-dom'

export function PurchasePage() {
  const navigate = useNavigate()

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
  }

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const [productType, setProductType] = useState<ProductType>('SPB')

  const min1Img = 'https://dev.optchaibez.ru/api/images/min1.png'
  const min2Img = 'https://dev.optchaibez.ru/api/images/min2.png'
  const min3Img = 'https://dev.optchaibez.ru/api/images/min3.png'
  const min4Img = 'https://dev.optchaibez.ru/api/images/min4.png'
  const teaNumber1 = 'https://dev.optchaibez.ru/api/images/teaNumber1.png'
  const teaNumber2 = 'https://dev.optchaibez.ru/api/images/teaNumber2.png'
  const teaNumber3 = 'https://dev.optchaibez.ru/api/images/teaNumber3.png'
  const cart1 = 'https://dev.optchaibez.ru/api/images/cart1.png'
  const cart2 = 'https://dev.optchaibez.ru/api/images/cart2.png'
  const cart3 = 'https://dev.optchaibez.ru/api/images/cart3.png'
  const delivery = 'https://dev.optchaibez.ru/api/images/delivery.png'
  const contactsInfo = 'https://dev.optchaibez.ru/api/images/contactsInfo.png'
  const paymentInfo = 'https://dev.optchaibez.ru/api/images/paymentInfo.png'
  const productTypeImg = 'https://dev.optchaibez.ru/api/images/productType.png'

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const setType = (type: ProductType) => {
    setProductType(type)
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledPurchasePage>
        <BackToCatalog />

        <div className="purchase-container">
          <h1>Как купить</h1>
          <br />
          <p>
            Мы отправляем заказы как со склада в Санкт-Петербурге, так и напрямую из Китая. Выберите точку отгрузки из
            списка под поисковой строкой:</p>
          <br />
          <br />
          <div className="product-type image-container">
            <img src={productTypeImg} alt="product type" />
          </div>
          <br />
          <div className="type-selector">
            <p
              className={productType === 'SPB' ? 'active' : ''}
              onClick={() => setType('SPB')}
            >
              Со склада в Спб
            </p>

            <p
              className={productType === 'CHINA' ? 'active' : ''}
              onClick={() => setType('CHINA')}
            >
              Со склада в Китае
            </p>
          </div>
          <br />
          <br />

          {productType === 'SPB' && <div className="spb-purchase purchase">
            <h2>1. Выберите необходимое количество товара:</h2>
            <br />
            <p>Мин. заказ на позицию:</p>
            <br />
            <div className="min-order-position">
              <img src={min1Img} alt="min 1" />
              <img src={min2Img} alt="min 2" />
              <img src={min3Img} alt="min 3" />
            </div>
            <br />
            <br />
            <div className="image-container product-position">
              <img src={teaNumber1} alt="product type" />
              <br />
              <br />
              <p><span className="red">Красным</span> помечены позиции, на которые идёт распродажа, скидки на них
                суммируются со скидками на объём заказа.</p>
              <br />
              <p><span className="green">Зелёным</span> цветом отмечены новинки ассортимента.</p>
            </div>
            <br />
            <br />

            <h2>2. Перейдите в корзину и проверьте верность заказа</h2>
            <br />
            <div className="image-container">
              <img src={cart1} alt="cart 1" />
            </div>
            <br />
            <div className="image-container">
              <img src={cart2} alt="cart 2" />
              <br />
              <br />
              <p><b>Сумма к оплате</b> находится в строке «Итого». Также, вы можете ознакомиться с предварительным весом
                вашего заказа.</p>
              <br />
              <p>Для перехода к <b>способам доставки</b> нажмите «Оформить».</p>
            </div>
            <br />
            <br />

            <h2>3. Выберите способ доставки</h2>
            <br />
            <div className="image-container">
              <img src={delivery} alt="delivery" />
              <br />
              <br />
              <p><b>В регионы</b> доставка грузов до 50кг осуществляется СДЭКом, для грузов больше 50кг возможна
                доставка Желдором и ПЭКом</p>
              <br />
              <p><b>По Санкт-Петербургу</b> доставка возможна до отделения, курьером до адреса, а также есть опция
                самовывоза с нашего склада в рабочее время</p>
            </div>
            <br />
            <br />

            <h2>4. Заполните данные для вашего способа доставки</h2>
            <br />
            <div className="image-container">
              <img src={contactsInfo} alt="contacts info" />
            </div>
            <br />
            <br />

            <h2>5. Выберите способ оплаты</h2>
            <br />
            <div className="image-container">
              <img src={paymentInfo} alt="payment info" />
            </div>
            <br />
            <br />

            <h2>6. Проверьте верность заполнения и нажмите «Оформить»</h2>
            <br />
            <br />

            <h2>7. Подтвердите оплату</h2>
            <p><b>После подтверждения заказа</b> и проверки наличия товара, менеджер пришлет вам сумму к оплате в
              рублях. Оплатите заказ и отправьте квитанцию о переводе</p>
            <p>Как только будет <b>подтверждена оплата</b>, заказ поступит в работу. Мы соберем, упакуем и отправим
              груз. После отправки заказа вышлем фотоотчёт и накладную для отслеживания груза</p>
            <br />
            <br />

            <h2>8. Получите и проверьте груз</h2>
            <p><b>При получении груза</b> проверьте целостность внешней упаковки, в случае повреждений сделайте фото.
              Затем проверьте наличие товара согласно файлу заказа. </p>
            <br />
            <br />

            <h3>Если возникнут вопросы – обращайтесь!</h3>
          </div>
          }

          {productType === 'CHINA' && <div className="china-purchase purchase">
            <h2>1. Выберите необходимое количество товара:</h2>
            <br />
            <p>Общая минимальная сумма заказа по Китаю 3000 юаней без учёта доставки.</p>
            <br />
            <p>Мин. заказ на позицию:</p>
            <br />
            <div className="min-order-position">
              <img src={min4Img} alt="min 4" />
              <img src={min2Img} alt="min 2" />
            </div>
            <br />
            <br />
            <div className="image-container product-position">
              <img src={teaNumber2} alt="product type" />
              <br />
              <br />
              <p>Многие позиции идут только тунами (связками) или коробками, <b>штучно их заказать нельзя</b>.</p>
            </div>
            <br />
            <div className="image-container product-position">
              <img src={teaNumber3} alt="product type" />
              <br />
              <br />
              <p>
                При увеличении заказа на позицию – цена на товар снижается. <b>Скидка идёт от 5 кг</b> и ещё раз
                повышается
                при заказе от коробки, как на рассыпной чай, так и на пресс. Количество товара в коробке отображается
                при выборе коробки в единицах измерения.
              </p>
            </div>
            <br />
            <br />

            <h2>2. Перейдите в корзину и проверьте верность заказа</h2>
            <br />
            <div className="image-container">
              <img src={cart3} alt="cart 3" />
            </div>
            <br />
            <p>
              Сумма к оплате находится в строке «Итого». Также, вы можете ознакомиться с предварительным весом вашего
              заказа без учета упаковки. <b>Упаковка добавляет около 20% к весу груза</b>.
            </p>
            <br />
            <p>
              После проверки нажмите «Оформить» и заказ <b>перейдёт в обработку менеджеру</b>, после чего он свяжется с
              вами для уточнения деталей оплаты и доставки
            </p>
            <br />
            <br />

            <h2>3. Согласуйте с менеджером доставку</h2>
            <br />
            <p>
              Из Китая нашими карго доставка осуществляется до Москвы по фиксированным тарифам, подробнее в разделе <a
                onClick={() => setRouter('/delivery')}>Доставка</a>.
            </p>
            <p>
              В случае, если вы решите воспользоваться своей логистикой - мы доставляем в пределах Китая до терминала
              любой транспортной компании. <b>Доставка по Гуанчжоу осуществляется бесплатно</b>, доставка в другие
              регионы
              оплачивается отдельно после отправки груза.
            </p>
            <br />
            <br />

            <h2>4. Оплатите товар и пришлите чек об оплате</h2>
            <br />
            <p>
              После подтверждения оплаты заказ отправляется в работу. Срок отгрузки до 5 рабочих дней. Подробнее о
              способах оплаты в разделе <a onClick={() => setRouter('/payment')}>Оплата</a>.
            </p>
            <p>После сбора менеджер пришлет вам фотоотчет с комплектацией груза.</p>
            <br />
            <br />

            <h2>5. Оплатите доставку</h2>
            <br />
            <p><b>По прибытии груза в Москву</b> с вами свяжется менеджер транспортной компании и пришлет реквизиты и
              курс для оплаты доставки.</p>
            <br />
            <br />

            <h2>6. Получите и проверьте груз</h2>
            <br />
            <p><b>При получении груза</b> проверьте целостность внешней упаковки, в случае повреждений сделайте фото.
              Затем проверьте наличие товара согласно файлу заказа. </p>
            <br />
            <br />

            <h3>Если возникнут вопросы – обращайтесь!</h3>
          </div>}
        </div>
      </StyledPurchasePage>
    </ThemeProvider>
  )
}
