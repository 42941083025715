import { useEffect, useState } from 'react'

export function useAppAvailability(): boolean | null {
  const [isAvailable, setIsAvailable] = useState<null | boolean>(null)

  const checkAppAvailability = () => {
    const userAgent = navigator.userAgent || navigator.vendor
    const isAndroid = /android/i.test(userAgent)
    const isIOS = /iPad|iPhone|iPod/.test(userAgent)
    const screenWidth = window.innerWidth

    const available = !(isAndroid || isIOS || screenWidth < 760)
    setIsAvailable(available) // Устанавливаем корректное значение
  }

  useEffect(() => {
    checkAppAvailability()

    const handleResize = () => {
      checkAppAvailability()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isAvailable
}
