import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { StyledPlugPage } from '@components/views/plugPage/styles/plugPage.styled'
import { Button, Drawer } from '@mui/material'
import Icons from '@icons/icons'
import { IStore } from '@store/store'
import { AboutPage } from '@components/views/aboutPage/aboutPage'
import { PricePage } from '@components/views/pricePage/pricePage'
import { DeliveryPage } from '@components/views/deliveryPage/deliveryPage'
import { PaymentPage } from '@components/views/paymentPage/paymentPage'
import { ContactsPage } from '@components/views/contactsPage/contactsPage'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../styles'
import vk from '../../../assets/img/social/vk.svg'
import youtube from '../../../assets/img/social/youtube.svg'
import telegram from '../../../assets/img/social/telegram.svg'
import lamp from '../../../assets/img/lamp.png'

export function PlugPage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const navigate = useNavigate()
  const currentPath = useLocation().pathname

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
    toggleDrawer(false)
  }

  const [anchor, setAnchor] = React.useState(false)

  const toggleDrawer = (open: boolean) => {
    setAnchor(open)
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledPlugPage>
        <header>
          <div className="menu-logo" onClick={() => setRouter('')}>
            <div className="logo">
              <Icons name='logo-medium' color='' size='30' className='icon' />
            </div>
            <div className="text">
              <p className="first">чай без церемоний</p>
              <p className="second">оптом</p>
            </div>
          </div>

          <Button onClick={() => toggleDrawer(true)}>
            {!anchor && <Icons name="menu" color="#B70A06" size="24" className="icon" />}
            {anchor && <Icons name="cancel" color="#B70A06" size="24" className="icon" />}
          </Button>
        </header>

        {currentPath === '/' && (<div className="info-container">
          <h2>Мобильная версия сайта в разработке</h2>

          <p>Заказ можно оформить либо в десктопной версии, либо скачать прайс, заполнить и выслать менеджеру:</p>

          <nav>
            <div className='not-active' onClick={() => setRouter('price')}>
              <Icons name="chain" color="" size="24" className="icon" />
              <p>Скачать прайс лист</p>
            </div>
            <div className='not-active' onClick={() => setRouter('contacts')}>
              <Icons name="chain" color="" size="24" className="icon" />
              <p>Контакты</p>
            </div>
          </nav>

          <p>
            Информацию о доставке и оплате можно получить, воспользовавшись меню сайта
          </p>

          <div className="logo-container">
            <div className="logo-group">
              <a href="https://vk.com/chaibez" target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer">
                <img src={vk} alt="vk" />
              </a>

              <a
                href="https://www.youtube.com/@%D0%A7%D0%B0%D0%B9%D0%B1%D0%B5%D0%B7%D0%A6%D0%B5%D1%80%D0%B5%D0%BC%D0%BE%D0%BD%D0%B8%D0%B9"
                target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer">
                <img src={youtube} alt="youtube" />
              </a>

              <a
                href="https://t.me/chaibeztea"
                target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer">
                <img src={telegram} alt="telegram" />
              </a>
            </div>
            <div className="logo-group">
              <img src={lamp} alt="lamp" />
            </div>
          </div>
        </div>)}

        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/price" element={<PricePage />} />
          <Route path="/delivery" element={<DeliveryPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
        </Routes>

        <Drawer
          anchor="bottom"
          open={anchor}
          onClose={() => toggleDrawer(false)}
        >
          <div>
            <nav>
              <div className={currentPath === '/price' ? 'active' : 'not-active'} onClick={() => setRouter('price')}>Скачать прайс лист</div>
              <div className={currentPath === '/delivery' ? 'active' : 'not-active'} onClick={() => setRouter('delivery')}>Доставка</div>
              <div className={currentPath === '/payment' ? 'active' : 'not-active'} onClick={() => setRouter('payment')}>Оплата</div>
              <div className={currentPath === '/about' ? 'active' : 'not-active'} onClick={() => setRouter('about')}>О нас</div>
              <div className={currentPath === '/contacts' ? 'active' : 'not-active'} onClick={() => setRouter('contacts')}>Контакты</div>
            </nav>

            <div className="logo-group">
              <a href="https://vk.com/chaibez" target="_blank" onClick={(e) => e.stopPropagation()} rel="noreferrer">
                <img src={vk} alt='vk' />
              </a>

              <a
                href='https://www.youtube.com/@%D0%A7%D0%B0%D0%B9%D0%B1%D0%B5%D0%B7%D0%A6%D0%B5%D1%80%D0%B5%D0%BC%D0%BE%D0%BD%D0%B8%D0%B9'
                target='_blank' onClick={(e) => e.stopPropagation()} rel="noreferrer">
                <img src={youtube} alt='youtube' />
              </a>

              <a
                href='https://t.me/chaibeztea'
                target='_blank' onClick={(e) => e.stopPropagation()} rel="noreferrer">
                <img src={telegram} alt='telegram' />
              </a>
            </div>
          </div>
        </Drawer>
      </StyledPlugPage>
    </ThemeProvider>
  )
}
