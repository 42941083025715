import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { IStore, useAppDispatch } from '@store/store'
import { initialFilters, updateProductFilterThunk } from '@store/products'
import Icons from '@icons/icons'
import { StyledMenuLogo } from '@components/header/components/menu/components/menuLogo/styles/menuLogo.styled'
// @ts-ignore
import { Routers } from '@types/routers'

import vk from '../../../../../../assets/img/social/vk.svg'
import youtube from '../../../../../../assets/img/social/youtube.svg'
import telegram from '../../../../../../assets/img/social/telegram.svg'
import { commonStyle } from '../../../../../../styles'


export function MenuLogo() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isDisabledFilters = useSelector((state: IStore) => state.products.isProductsUpdate)

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
    dispatch(updateProductFilterThunk(initialFilters))
  }

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const reload = () => {
    if(!isDisabledFilters) {
      setRouter('/')
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledMenuLogo>
        <div className='icon-container' onClick={reload}>
          <Icons name='logo' color='' size='58' className='icon' />
        </div>

        <div className='name-container' onClick={reload}>
          <h2>Чай без церемоний</h2>

          <p>Оптом</p>

          <div className='socials'>
            <a
              href='https://vk.com/chaibez'
              target='_blank'
              onClick={(e) => e.stopPropagation()} rel="noreferrer"
            >
              <img src={vk} alt='vk' />
            </a>

            <a
              href='https://www.youtube.com/@%D0%A7%D0%B0%D0%B9%D0%B1%D0%B5%D0%B7%D0%A6%D0%B5%D1%80%D0%B5%D0%BC%D0%BE%D0%BD%D0%B8%D0%B9'
              target='_blank'
              onClick={(e) => e.stopPropagation()} rel="noreferrer"
            >
              <img src={youtube} alt='youtube' />
            </a>

            <a
              href='https://t.me/chaibeztea'
              target='_blank'
              onClick={(e) => e.stopPropagation()} rel="noreferrer"
            >
              <img src={telegram} alt='telegram' />
            </a>
          </div>
        </div>
      </StyledMenuLogo>
    </ThemeProvider>
  )
}
