import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledTSelector = styled.div.attrs(() => ({
  className: 't-selector',
}))`
  height: 100%;
  
  .selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    height: 2.25rem;
    padding: 0.5rem;
    background-color: ${() => commonStyle.colors.white};
    font-family: 'MontserratFont', sans-serif;
    font-size: ${() => commonStyle.fonts.fs14};
    font-weight: ${() => commonStyle.fontStyles.fw400};
    box-shadow: ${() => commonStyle.shadows.primaryShadow};
    border: none;
    border-radius: ${() => commonStyle.radii.radius8};
    cursor: pointer;
    user-select: none;
    
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      
      .box {
        font-size: ${() => commonStyle.fonts.fs9};
        color: ${() => commonStyle.colors.grey500};
      }
    }
  }
  
  .selector-disabled {
    justify-content: center;
  }

  .MuiFormControl-root {
    display: block;
    visibility: hidden;
    position: relative;
    top: -0.8rem;
    width: 100%;
    height: 0;
    margin: 0;

    .MuiInputBase-root {
      width: 100%;
      padding: 0;

      .MuiSelect-select {
        display: none;
        width: 100%;
      }
      
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }

      .MuiSvgIcon-root {
        display: none;
      }
    }
  }
`
