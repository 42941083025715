import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledContactsPage = styled.div.attrs(() => ({
  className: 'contacts-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    'back'
    'contacts';

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;

  .contacts-container {
    grid-area: contacts;

    padding-bottom: 3rem;
    
    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }
    
    .card-container {
      display: flex;
      gap: 1rem;
      
      .card {
        display: flex;
        flex-direction: column;
        height: 17rem;
        padding: 1rem;
        border: 1px solid ${() => commonStyle.colors.grey500};
        border-radius: ${() => commonStyle.radii.radius16};

        h2 {
          font-size: ${() => commonStyle.fonts.fs26};
          color: ${() => commonStyle.colors.red};
          font-weight: ${() => commonStyle.fontStyles.fw600};
          text-transform: uppercase;
          margin-bottom: 1rem;
        }
        
        .info {
          display: flex;
          flex-direction: column;
          justify-content: end;
          flex-grow: 1;
        }

        p {
          font-size: ${() => commonStyle.fonts.fs20};
          line-height: 160%;
        }

        .small {
          font-size: ${() => commonStyle.fonts.fs14};
        }
        
        a{
          color: ${props => props.theme.color};
          text-decoration: underline;
        }
      }
      
      .order {
        flex-basis: 35%;
        padding-bottom: 1.5rem;
      }
      
      .pickup {
        flex-basis: 35%;
        padding-bottom: 1.5rem;

        .info {
          p {
            max-width: 16rem;
          }
        }
      }
      
      .social {
        flex-basis: 30%;
        
        .contacts {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          
          .contact {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 0.75rem;
            
            img {
              width: 32px;
              height: 32px;
            }
            
            p {
              font-size: ${() => commonStyle.fonts.fs14};
            }
            
            a {
              font-size: ${() => commonStyle.fonts.fs14};
              color: ${props => props.theme.color};
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    .back-to-catalog {
      display: none;
    }

    .contacts-container {
      h1 {
        font-size: ${() => commonStyle.fonts.fs20};
        font-weight: ${() => commonStyle.fontStyles.fw600};
        text-align: start;
      }

      .card-container {
        flex-direction: column;
        
        .card {
          height: fit-content;
          
          h2 {
            font-size: ${() => commonStyle.fonts.fs20};
            text-align: start;
          }

          p {
            font-size: ${() => commonStyle.fonts.fs14};
            text-align: start;
          }
        }

        .social {
          .contacts {
            .contact {
              img {
                width: 28px;
                height: 28px;
              }
            }
          }
        }
      }
    }
  }
`
