import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledDeliveryPage = styled.div.attrs(() => ({
  className: 'delivery-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    "back"
    "delivery";

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;

  .delivery-container {
    grid-area: delivery;

    max-width: 37.5rem;
    margin: 0 auto;
    padding-bottom: 3rem;

    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }

    p {
      font-size: ${() => commonStyle.fonts.fs14};
      line-height: 160%;
    }

    .table-container {
      .row {
        display: flex;
        align-items: center;
        height: 3.125rem;
        padding: 0 1rem;
        border-bottom: 1px solid ${() => commonStyle.colors.grey300};

        p {
          flex-basis: 40%;
          text-align: center;
          text-wrap: wrap;

          &:first-child {
            flex-basis: 20%;
            text-align: start;
            color: ${props => props.theme.secondColor};
          }
        }

        &:first-child {
          p {
            color: ${props => props.theme.secondColor};
          }
        }
      }
    }
    
    .table-mobile-container {
      display: none;
      
      p {
        font-size: ${() => commonStyle.fonts.fs12};
      }
      
      .row {
        display: flex;
        align-items: center;
        height: 3.125rem;
        padding: 0;
        border-bottom: 1px solid ${() => commonStyle.colors.red};
        
        p {
          font-size: ${() => commonStyle.fonts.fs12};
          text-align: center;
        }
        
        p:first-child {
          flex-basis: 40%;
        }
        
        p:last-child {
          flex-grow: 1;
        }
      }
      
      .first-raw {
        border-top: 1px solid ${() => commonStyle.colors.red};
      }
    }
  }

  @media screen and (max-width: 761px) {
    .back-to-catalog {
      display: none;
    }

    .delivery-container {
      h1 {
        font-size: ${() => commonStyle.fonts.fs20};
        font-weight: ${() => commonStyle.fontStyles.fw600};
        text-align: start;
      }

      p {
        text-align: start;
      }

      .table-container {
        display: none;
      }

      .table-mobile-container {
        display: block;
      }
    }
  }
`
