import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import { LicenseAgreementProps } from '@components/authorization/components/licenseAgreement/types/types'
import { StyledLicenseAgreement } from '@components/authorization/components/licenseAgreement/styles/licenseAgreement.styled'

import { commonStyle } from '../../../../styles'

export function LicenseAgreement(props: LicenseAgreementProps) {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const licenseAgreementElement = useSelector((state: IStore) => state.auth.licenseAgreementElement)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledLicenseAgreement>
        <div style={{ overflowY: 'auto', maxHeight: '100%' }}>
          <div dangerouslySetInnerHTML={{ __html: licenseAgreementElement }} />
        </div>
      </StyledLicenseAgreement>
    </ThemeProvider>
  )
}
