import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore } from '@store/store'
import { StyledAboutPage } from '@components/views/aboutPage/styles/aboutPage.styled'
import { BackToCatalog } from '@components/views/components/backToCatalog/backToCatalog'

import { commonStyle } from '../../../styles'

export function AboutPage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const chines_door = 'https://dev.optchaibez.ru/api/images/chines_door.png'

  return (
    <ThemeProvider theme={theme}>
      <StyledAboutPage>
        <BackToCatalog />

        <div className="about-container">
          <h1>О нас</h1>
          <br />
          <br />
          <p>
            Компания «Чай Без Церемоний» с 2012 года занимается оптовой и розничной продажей традиционного китайского и
            тайваньского чая, посуды, упаковки и аксессуаров для чаепития. С первого года работы мы начали поставлять
            товар из Китая, в дальнейшем я (Панасевич Алексей Владимирович, основатель и руководитель компании) прожил 6
            лет в Китае, изучал язык, чай, ездил на поля и фабрики, осваивал логистику и тонкости торговли.
          </p>
          <br />
          <p>
            За долгие годы у нас сформировалась база поставщиков со стабильным качеством. Среди них как небольшие
            фермеры, так и крупные фабрики. Мы постоянно заказываем образцы всех урожаев, сравниваем и отбираем лучшее.
            Так же регулярно выезжаем в Китай для обновления ассортимента, углубления знаний о чае и съёмок контента.
            Так же производим прессованные чаи как под собственным брендом, так и для клиентов.
          </p>
          <br />
          <p>
            На сегодняшний день у нас есть офис и склад в Санкт-Петербурге, с него мы поставляем товар оптом и в розницу
            по России и Белоруссии. В СПб весь товар хранится на нашем складе, отбирается, принимается, пакуется и
            отправляется нашими сотрудниками. Здесь осуществляется максимальный контроль качества на всех этапах работы,
            созданы условия для качественного хранения и упаковки товара.
          </p>
          <br />
          <p>
            Так же мы осуществляем отгрузки из Китая. Наши сотрудники закупают товар поставщиков, пакуют и отправляют.
            При данном виде отправки более выгодная цена, шире ассортимент и максимальная свежесть продукции. Но дольше
            срок поставки и меньший контроль качества, т.к. товар находится не на нашем складе.
          </p>
          <br />
          <p>
            У нас есть оптовый и розничный сайты, страницы в <a href='https://vk.com/chaibezopt' target='_blank' rel="noreferrer">VK</a>, <a href='https://t.me/chaibeztea' target='_blank' rel="noreferrer">Telegram </a>
            и канал на <a href='https://www.youtube.com/@%D0%A7%D0%B0%D0%B9%D0%B1%D0%B5%D0%B7%D0%A6%D0%B5%D1%80%D0%B5%D0%BC%D0%BE%D0%BD%D0%B8%D0%B9' target='_blank' rel="noreferrer">Youtube</a>. На этих ресурсах мы делимся знаниями о чае, практическими наработками,
            новостями, и просто
            мыслями о жизни и чайной культуре.
          </p>
          <br />
          <p>
            Добро пожаловать в наше чайное сообщество!
          </p>
          <br />
          <br />
          <img src={chines_door} alt="chines_door" />
        </div>
      </StyledAboutPage>
    </ThemeProvider>
  )
}
