import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledPaymentPage = styled.div.attrs(() => ({
  className: 'payment-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    'back'
    'payment';

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;

  .payment-container {
    grid-area: payment;

    max-width: 37.5rem;
    margin: 0 auto;
    padding-bottom: 3rem;
    
    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }

    h2 {
      font-size: ${() => commonStyle.fonts.fs20};
      color: ${() => commonStyle.colors.red};
      font-weight: ${() => commonStyle.fontStyles.fw600};
    }
    
    ul {
      list-style: disc;
      margin-left: 1rem;
      display: inline-block;
      font-size: ${() => commonStyle.fonts.fs14};
      
      li {
        margin-top: 1rem;
      }

      li:first-child {
        margin-top: 0;
      }
    }
    
    p {
      font-size: ${() => commonStyle.fonts.fs14};
      line-height: 160%;
    }
  }

  @media screen and (max-width: 761px) {
    .back-to-catalog {
      display: none;
    }

    .payment-container {
      h1 {
        font-size: ${() => commonStyle.fonts.fs20};
        font-weight: ${() => commonStyle.fontStyles.fw600};
        text-align: start;
      }

      h2 {
        font-size: ${() => commonStyle.fonts.fs14};
        font-weight: ${() => commonStyle.fontStyles.fw600};
        text-align: start;
      }
      
      ul {
        display: block;
        li {
          text-align: start;
        }
      }

      .br {
        display: none;
      }

      p {
        text-align: start;
      }
    }
  }
`
