import { createGlobalStyle } from 'styled-components'

import { commonStyle } from '../../../../styles'

export const MobileStyles = createGlobalStyle`
  .MuiDrawer-root {
    .MuiBackdrop-root {
      background-color: transparent;
    }

    .MuiPaper-root {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - (var(--mobile-header-height) + 1rem));
      top: calc(var(--mobile-header-height) + 1rem);
      padding: 0 2rem;
      box-shadow: none;

      div {
        nav {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.563rem;
          margin-top: -2rem;

          div {
            font-size: ${() => commonStyle.fonts.fs20};
            font-weight: ${() => commonStyle.fontStyles.fw600};
            color: ${() => commonStyle.colors.red};
            text-transform: lowercase;
          }
        }

        .logo-group {
          display: flex;
          justify-content: center;
          gap: 2rem;
          margin-top: 2.5rem;

          img {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
`
