import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { StyledDeliveryPage } from '@components/views/deliveryPage/styles/deliveryPage.styled'
import { IStore } from '@store/store'
import { BackToCatalog } from '@components/views/components/backToCatalog/backToCatalog'

import { commonStyle } from '../../../styles'

export function DeliveryPage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledDeliveryPage>
        <BackToCatalog />

        <div className="delivery-container">
          <h1>Доставка чая из Китая.</h1>
          <h1>Условия.</h1>
          <br />
          <br />
          <p>
            Доставка заказов осуществляется карго из Китая до Москвы, из Москвы российскими транспортными в
            регионы.
          </p>
          <br />
          <p>
            <b>Оплата доставки</b> осуществляется по прибытии груза в Москву, менеджер транспортной свяжется с вами,
            сообщит
            сумму и реквизиты для оплаты. После подтверждения оплаты груз передадут в транспортную компанию, в Мск
            возможен самовывоз. Оплата осуществляется в рублях, по курсу транспортной компании.
          </p>
          <br />
          <br />
          <div className="table-container">
            <div className="row">
              <p></p>
              <p>Доставка авто до 100 кг</p>
              <p>Доставка авто от 100 кг</p>
            </div>

            <div className="row">
              <p>Обычное авто </p>
              <p>18-30 дней, 6 $/кг</p>
              <p></p>
            </div>

            <div className="row">
              <p>Быстрое авто </p>
              <p>15-20 дней, 7 $/кг</p>
              <p>15-19 дней, 3.8 $/кг</p>
            </div>

            <div className="row">
              <p>Мин вес груза </p>
              <p>от 20 кг с учётом упаковки</p>
              <p>от 100 кг с учётом упаковки</p>
            </div>

            <div className="row">
              <p>Страховка</p>
              <p>
                2% от заявленной
                <br />
                стоимости груза
              </p>
              <p>
                1% от заявленной
                <br />
                стоимости груза
              </p>
            </div>
          </div>
          <div className="table-mobile-container">
            <p><b>Доставка авто до 100 кг</b></p>
            <br />
            <div className="row first-raw">
              <p>Обычное авто</p>
              <p>18-30 дней, 6 $/кг</p>
            </div>

            <div className="row">
              <p>Быстрое авто</p>
              <p>15-20 дней, 7 $/кг</p>
            </div>

            <div className="row">
              <p>Мин вес груза</p>
              <p>от 20 кг с учётом упаковки</p>
            </div>

            <div className="row">
              <p>Страховка</p>
              <p>2% от заявленной стоимости груза</p>
            </div>

            <br />
            <br />
            <br />

            <p><b>Доставка авто от 100 кг</b></p>
            <br />
            <div className="row first-raw">
              <p>Обычное авто</p>
              <p></p>
            </div>

            <div className="row">
              <p>Быстрое авто</p>
              <p>15-19 дней, 3.8 $/кг</p>
            </div>

            <div className="row">
              <p>Мин вес груза</p>
              <p>от 100 кг с учётом упаковки</p>
            </div>

            <div className="row">
              <p>Страховка</p>
              <p>1% от заявленной стоимости груза</p>
            </div>
          </div>
          <br />
          <br />
          <p>
            <b>Упаковка составляет</b> примерно 20% веса, т.е. для доставки от 100 кг необходимо заказать 80 кг товара
            без
            упаковки.
          </p>
          <br />
          <p>
            <b>Страховка</b> распространяется на полную или частичную утерю груза. За повреждения груза транспортная
            компания
            ответственности не несет. Мы делаем качественную внутреннюю упаковку, транспортная делает внешнюю, поэтому
            наши грузы доходят в целостности.
          </p>
        </div>
      </StyledDeliveryPage>
    </ThemeProvider>
  )
}
